import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { LeadFragmentDoc } from '../../../../lead/api/fragments/generated/Lead.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetReferralProgramPaymentDatetimeMutationVariables = Types.Exact<{
  input: Types.SetReferralProgramPaymentDatetimeInput;
}>;


export type SetReferralProgramPaymentDatetimeMutation = { __typename?: 'Mutation', setReferralProgramPaymentDatetime: { __typename?: 'Lead', id: string, createdAt: any, isProcessed: boolean, status: Types.LeadStatus, updatedAt: any, userId: string, date?: any | null, inviteFriendDate?: any | null, referrerPaymentDatetime?: any | null, referralProgram?: { __typename?: 'ReferralProgramEntity', id: string, rewardForTheReferer?: number | null, numberOfGoodShifts?: number | null, name?: string | null, facility?: { __typename?: 'Facility', id: string, name: string } | null, facilityGroup?: { __typename?: 'FacilityGroup', id: string, name: string } | null } | null, user?: { __typename?: 'User', id: string, phone: string, firstname: string, lastname: string, patronymic?: string | null, role: Types.Role, goodShiftCount?: number | null, Facility_FOREMAN?: Array<{ __typename?: 'Facility', id: string, name: string }> | null, workposts?: Array<{ __typename?: 'WorkPost', userId: string, status: Types.WorkPostStatus, positionId: string, facilityId: string, facility?: { __typename?: 'Facility', id: string, name: string } | null }> | null, referrer?: { __typename?: 'User', id: string, firstname: string, lastname: string, patronymic?: string | null, role: Types.Role, phone: string, Facility_FOREMAN?: Array<{ __typename?: 'Facility', id: string, name: string }> | null, workposts?: Array<{ __typename?: 'WorkPost', userId: string, status: Types.WorkPostStatus, positionId: string, facilityId: string, facility?: { __typename?: 'Facility', id: string, name: string } | null }> | null } | null } | null } };


export const SetReferralProgramPaymentDatetimeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SetReferralProgramPaymentDatetime"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SetReferralProgramPaymentDatetimeInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"setReferralProgramPaymentDatetime"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Lead"}}]}}]}},...LeadFragmentDoc.definitions]} as unknown as DocumentNode;
export type SetReferralProgramPaymentDatetimeMutationFn = Apollo.MutationFunction<SetReferralProgramPaymentDatetimeMutation, SetReferralProgramPaymentDatetimeMutationVariables>;

/**
 * __useSetReferralProgramPaymentDatetimeMutation__
 *
 * To run a mutation, you first call `useSetReferralProgramPaymentDatetimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetReferralProgramPaymentDatetimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setReferralProgramPaymentDatetimeMutation, { data, loading, error }] = useSetReferralProgramPaymentDatetimeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetReferralProgramPaymentDatetimeMutation(baseOptions?: Apollo.MutationHookOptions<SetReferralProgramPaymentDatetimeMutation, SetReferralProgramPaymentDatetimeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetReferralProgramPaymentDatetimeMutation, SetReferralProgramPaymentDatetimeMutationVariables>(SetReferralProgramPaymentDatetimeDocument, options);
      }
export type SetReferralProgramPaymentDatetimeMutationHookResult = ReturnType<typeof useSetReferralProgramPaymentDatetimeMutation>;
export type SetReferralProgramPaymentDatetimeMutationResult = Apollo.MutationResult<SetReferralProgramPaymentDatetimeMutation>;
export type SetReferralProgramPaymentDatetimeMutationOptions = Apollo.BaseMutationOptions<SetReferralProgramPaymentDatetimeMutation, SetReferralProgramPaymentDatetimeMutationVariables>;